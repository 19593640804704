<template>
    <v-layout style="margin: 0px; padding: 0px; width: 100%;">
      <v-row>
        <v-col col="12" md="5">
          <v-row wrap>
            <v-col cols="10" offset="1">
            
  
              <v-row style="margin-top:100px;">
                <v-col cols="12" align="center">
                  <router-link to="/">
                    <v-img
                      class="centerImg2"
                      :src="'img/logo.png'"
                    />
                  </router-link>
                </v-col>
              </v-row>
  
              <v-row style="margin-top:10px;">
                <v-col cols="12" align="center">
                  <span class="titleStyleColor">Enter Backup Code</span> <br />
                  <span style="color:#7CBF46">
                    Open the two-factor authentication app on your device to view your authentication code  and verify your identity</span
                  >
                </v-col>
              </v-row>
  
              <v-layout wrap>
                <v-progress-linear
                  color="#7CBF46"
                  v-show="loader"
                  :indeterminate="true"
                ></v-progress-linear>
                <v-col cols="3"> </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-text-field
                      v-model="codeData"
                      outlined
                      placeholder="Backup Code"
                      dense
                      color="#7CBF46"
                      :maxlength="10"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-layout>
  
              <v-layout wrap>
                <v-col col="12" align="center" justify="center">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                   <h5 class="text-success" align="center">
                    {{ Message }}
                  </h5>
                  <v-btn align="center" class="buttonStyle" @click="verifyLogin">
                    Submit
                  </v-btn>
                  <p class="mt-5" style="font-size:11px;">
                    <v-btn small text :to="{ name: 'pharma2faVerification', params: { email: this.$route.params.email } }">Use OTP from App.</v-btn>
                  </p>
                </v-col>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
        <v-col col="12" md="7" class="loginPadding hidden-sm-and-down">
          <v-img
            contain
            src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632485906/loginImage_blv0va.png"
            style="margin: 0px; padding: 0px; width: 100%"
          >
            <div class="overlay"></div>
          </v-img>
        </v-col>
      </v-row>
    </v-layout>
  </template>
  
  <script>
  export default {
    name: "CodeVerification",
  
    data: () => ({
      loader: false,
      codeData: "",
      errorMessage: "",
      Message: "",
      email:"",
      userId: "",
      timerCount: 60,
      resendBtn: false,
    }),
    watch: {
      timerCount: {
        handler(value) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          } else {
            this.resendBtn = true;
          }
        },
        immediate: true, // This ensures the watcher is triggered upon creation
      },
    },
  
    created() {
      this.userId = localStorage.getItem("userId");
      this.email = this.$route.params.email;
    },
    methods: {
      redirectback() {
        this.$router.go(-1);
      },
      verifyLogin() {
        this.loader = true;
        this.errorMessage = "";
        this.Message = "";

        const data = {
          backupCode:this.codeData
        }
        
        this.$http
          .post(
            `${process.env.VUE_APP_URL}login/verify/backupcode/` + this.userId,
            data
          )
          .then((response) => {
            this.loader = false;
            if (response.data.data.Role.name == "Admin") {
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              localStorage.setItem("userId", response.data.data.userId);
              this.$router.push({
                name: "pharmaAdminDashboard",
              });
            }
            if (response.data.data.Role.name == "User") {
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              localStorage.setItem("userId", response.data.data.userId);
              this.$router.push({
                name: "pharmaUserDashboard",
              });
            }
            if (response.data.data.Role.name == "Manufacturer") {
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              localStorage.setItem("userId", response.data.data.userId);
             
              this.$router.push({
                name: "pharmaManufacturerDashboard",
              });
            }
            if (response.data.data.Role.name == "Distributor") {
              this.loader = false;
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              localStorage.setItem(
                "distributorId",
                response.data.data.Distributor.distributorId
              );
              this.$router.push({
                name: "pharmaDistributorDashboard",
              });
            }
            if (response.data.data.Role.name == "Retailer") {
              this.loader = false;
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              this.$router.push({
                name: "pharmaRetailerDashboard",
              });
            }
  
            if (response.data.data.Role.name == "Professional") {
              this.loader = false;
              this.$auth.setToken(response.data.token.token, 240 + Date.now());
              this.$auth.setAuthenticatedUser(response.data.data);
              this.$router.push({
                name: "pharmaProfessionalDashboard",
              });
            }
            
          })
          .catch((error) => {
            this.loader = false;
            this.errorMessage = error.response.data.data;
            console.log(error.response);
          });
      },
      resendCode() {
        this.Message = "";
        this.loader = true;
        const data = {
          email:this.email
        };
  
        this.$http
          .post(
            `${process.env.VUE_APP_URL}login/resend-code`,
            data
          )
          .then(() => {
            this.loader = false;
            this.Message = "Code resent successfully.";
          });
      }
    },
  };
  </script>
  
  <style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
  }
  
  .loginPadding {
    margin: 0px !important;
    padding: 0px !important;
    background: url("https://res.cloudinary.com/ecrtechdev/image/upload/v1632485906/loginImage_blv0va.png")
      no-repeat center right fixed !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  }
  
  .titleStyle {
    width: 411px;
    font-family: ABeeZee;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
  }
  
  .titleStyleColor {
    width: 411px;
    font-family: ABeeZee;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    background: -webkit-linear-gradient(#72bd61, #111212);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .centerImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    text-align: center;
  }
  
  .centerImg2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    text-align: center;
  }
  
  .buttonStyle {
    width: auto;
    height: auto;
    background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
    border-radius: 10px;
    color: #ffffff !important;
    font-size: 14px;
  }
  </style>
  